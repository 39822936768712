import { shopDomain } from '@evolutivelabs/rhinoshield-shopify-client'
import * as cookie from 'cookie'
import { onMounted } from 'vue'

import { storeConfig } from '@/locale'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __st: {
      cid: number | undefined
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    gdpr_loaded: number | undefined
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Shopify: {
      shop: string
    }
  }
}

const consentMoConsentStatusKey = 'cookieconsent_status'

function initCookiesIfNotExist(): boolean {
  const cookies = cookie.parse(document.cookie)
  const consentStatus = cookies[consentMoConsentStatusKey]

  // If the user has not set this for GDPR, the cookie value will be undefined
  const isGdprConfigured = consentStatus !== undefined

  return isGdprConfigured
}

export default function useGdpr(): void {
  if (storeConfig.requireGdpr) {
    const isGdprConfigured = initCookiesIfNotExist()
    if (!isGdprConfigured) {
      onMounted(() => {
        injectGdprAppScript()
      })
    }
  }

  function injectGdprAppScript(): void {
    window.Shopify = { shop: shopDomain }
    window.gdpr_loaded = undefined
    window.__st = { cid: undefined }

    const script = document.createElement('script')
    script.defer = true
    script.src = `https://gdprcdn.b-cdn.net/js/gdpr_cookie_consent.min.js?shop=${shopDomain}`
    script.onload = () => {
      addWatcher()
    }
    document.head.appendChild(script)
  }

  const SUBMIT_BUTTONS_COMMON_CLASS = 'isense-cc-submit-consent'
  const CONTAINERS_CLASSES = [
    'cc-window', // FAB on the bottom
    'cc-settings-view', // pop-up settings
  ]

  /**
   * watch GDPR elements is created or not
   */
  function addWatcher(): void {
    const submitButtons: Element[] = []
    const observer = new MutationObserver(() => {
      const gdprElements = CONTAINERS_CLASSES.map((className) =>
        document.querySelector(`.${className}`),
      )

      if (gdprElements.every((element) => Boolean(element))) {
        submitButtons.push(...document.querySelectorAll(`.${SUBMIT_BUTTONS_COMMON_CLASS}`))
        submitButtons.forEach((element) => {
          element.addEventListener('click', onSubmit)
        })

        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
    })

    function onSubmit(event: Event): void {
      const element = event.target as HTMLElement

      if (element.classList.contains(SUBMIT_BUTTONS_COMMON_CLASS)) {
        submitButtons.forEach((element) => {
          element.removeEventListener('click', onSubmit)
        })
      }
    }
  }
}
