import { setCachedDataUrl } from '@evolutivelabs/amuse-design-canvas'
import { t } from 'i18next'
import { defineStore } from 'pinia'
import typia from 'typia'
import { useQuery } from 'villus'
import { readonly, ref, watch } from 'vue'

import { isBoundDeviceState } from '@/components/newProductPanel/newProductPanel.type'
import {
  AquaStandPerspectiveDocument,
  type BoundDeviceCategoryFieldsFragment,
  type BoundDeviceCategoryType,
  CatalogDocument,
  type CatalogQuery,
  type ProductSetFieldsFragment,
  type UnboundDeviceCategoryFieldsFragment,
  UnboundDeviceCategoryType,
} from '@/graphql/creator-platform/generated'
import { storeConfig } from '@/locale'
import { getDeviceImageUrl } from '@/utils/getDeviceImage'
import {
  getRecentProductsFromLocalStorage,
  type LocalStorage,
  setLocalStorage,
} from '@/utils/localStorage'
import {
  type BoundDeviceProductQueryString,
  getProductQueryString,
  type QueryStringInfo,
  setProductQueryString,
} from '@/utils/queryString'
import { sentry } from '@/utils/sentry'

import { useAlertStore } from './alertStore'
import { getBoundDeviceProductSets, getUnboundDeviceProductSets } from './newProduct'
import {
  type BoundDeviceProductIndicator,
  type BoundDeviceProductState,
  type BrandFragment,
  type CategoryFragment,
  type DeviceColorFragment,
  type DeviceFragment,
  type NewProductIndicator,
  type ProductSetGroup,
  type ProductState,
  type ProductStateWithSelectedProduct,
  type SelectedProduct,
  type UnboundDeviceProductIndicator,
} from './newProductStore.type'

export const useNewProductStore = defineStore('newProduct', () => {
  const alertStore = useAlertStore()
  const catalog = ref<CategoryFragment[] | null>(null)
  const productState = ref<null | ProductState>(null)

  watch(productState, (state) => {
    if (state === null) return
    if (isBoundDeviceState(state)) {
      const { device, color, selectedProduct } = state
      const mpn = selectedProduct?.variant.mpn ?? null
      if (color !== null && mpn !== null) {
        void setCachedDataUrl(getDeviceImageUrl(device.handle, mpn, color.handle))
      }
    }
    const variant = state.selectedProduct?.variant ?? null
    if (variant !== null) {
      const { preDesignLayers, postDesignLayers } = variant
      void Promise.all(
        [...preDesignLayers, ...postDesignLayers].map(async (layer) => {
          await setCachedDataUrl(layer)
        }),
      )
    }
  })

  async function initProcess(): Promise<void> {
    await initCatalog()

    const queryString = getProductQueryString()
    if (queryString !== null && (await initFromQueryString(queryString))) return

    const localStorage = getRecentProductsFromLocalStorage()
    if (localStorage !== null && (await initFromLocalStorage(localStorage))) return

    const category = catalog.value?.[0]
    if (category === undefined) {
      throw new Error('empty catalog')
    }
    await initProductState(category)
  }
  let initSingleton: null | Promise<void> = null
  async function init(): Promise<void> {
    if (initSingleton == null) {
      initSingleton = initProcess()
    }
    await initSingleton
  }
  void init()

  async function initCatalog(): Promise<void> {
    const { data, error } = await useQuery({
      query: CatalogDocument,
      variables: { locale: storeConfig.locale },
    })

    if (error.value !== null || data.value === null) {
      const errorMessage = `initCatalog failed with error: ${error.value?.toString() ?? ''}`
      sentry.error(errorMessage)
      throw new Error(errorMessage)
    }

    catalog.value = data.value.catalog
  }

  async function initFromQueryString(queryString: QueryStringInfo): Promise<boolean> {
    const indicator = transformQueryString(queryString)
    return await changeByIndicator(indicator)
  }

  async function initFromLocalStorage(
    localStorage: LocalStorage['recentProducts'],
  ): Promise<boolean> {
    const recentCategoryType = localStorage.recentCategoryType

    const indicator = typia.is<BoundDeviceCategoryType>(recentCategoryType)
      ? localStorage.boundDevice[recentCategoryType]
      : localStorage.unboundDevice[recentCategoryType]

    if (indicator === undefined) return false

    return await changeByIndicator(indicator)
  }

  async function changeByIndicator(indicator: NewProductIndicator): Promise<boolean> {
    if (typia.is<BoundDeviceProductIndicator>(indicator)) {
      const category = catalog.value?.find(
        (c) =>
          c.__typename === 'BoundDeviceCategoryDto' && c.boundDeviceType === indicator.categoryType,
      )
      if (category === undefined || category.__typename !== 'BoundDeviceCategoryDto') return false
      return await initProductState(category, indicator)
    } else {
      const category = catalog.value?.find(
        (c) =>
          c.__typename === 'UnboundDeviceCategoryDto' &&
          c.unboundDeviceType === indicator.categoryType,
      )
      if (category === undefined || category.__typename !== 'UnboundDeviceCategoryDto') return false
      return await initProductState(category, indicator)
    }
  }

  async function initProductState(
    category: BoundDeviceCategoryFieldsFragment,
    indicator: BoundDeviceProductIndicator,
  ): Promise<boolean>
  async function initProductState(
    category: UnboundDeviceCategoryFieldsFragment,
    indicator: UnboundDeviceProductIndicator,
  ): Promise<boolean>
  async function initProductState(category: CategoryFragment): Promise<boolean>
  async function initProductState(
    category: CatalogQuery['catalog'][number],
    indicator?: NewProductIndicator,
  ): Promise<boolean> {
    if (category.__typename === 'BoundDeviceCategoryDto') {
      if (typia.is<BoundDeviceProductIndicator>(indicator)) {
        return await initDeviceByIndicator(category, indicator)
      }

      const brand = category.brands[0]
      const device = brand?.devices[0]
      const color = device?.colors[0] ?? null

      return await initDevice({
        categoryType: category.boundDeviceType,
        brand,
        device,
        color,
      })
    } else {
      productState.value = { categoryType: category.unboundDeviceType, selectedProduct: null }
      const sets = await getUnboundDeviceProductSets(category.unboundDeviceType)
      return await initSelectedProduct(sets, indicator)
    }
  }

  async function initDevice(
    {
      categoryType,
      brand,
      device,
      color,
    }: {
      brand: BrandFragment | undefined
      categoryType: BoundDeviceCategoryType
      color: DeviceColorFragment
      device: DeviceFragment | undefined
    },
    indicator?: BoundDeviceProductIndicator,
  ): Promise<boolean> {
    if (brand === undefined || device === undefined) {
      sentry.warn(
        `initDevice failed with brand: ${JSON.stringify(brand)}, device: ${JSON.stringify(device)}`,
      )
      return false
    }
    productState.value = {
      categoryType,
      brandName: brand.name,
      device,
      color,
      selectedProduct: null,
    }

    const productSets = await getBoundDeviceProductSets(device.handle)
    return await initSelectedProduct(productSets, indicator)
  }

  async function initDeviceByIndicator(
    category: BoundDeviceCategoryFieldsFragment,
    indicator: BoundDeviceProductIndicator,
  ): Promise<boolean> {
    const brand = category.brands.find((b) =>
      b.devices.some((d) => d.handle === indicator.deviceHandle),
    )
    const device = brand?.devices.find((d) => d.handle === indicator.deviceHandle)
    const color =
      device?.colors.find((c) => c.handle === indicator.deviceColorHandle) ??
      device?.colors[0] ??
      null

    return await initDevice(
      {
        categoryType: category.boundDeviceType,
        brand,
        device,
        color,
      },
      indicator,
    )
  }

  async function assignSelectedProduct({
    state,
    selectedProduct,
  }: {
    readonly selectedProduct: {
      [k in keyof Omit<SelectedProduct, 'title'>]: SelectedProduct[k] | undefined
    }
    readonly state: ProductState
  }): Promise<boolean> {
    const { productSetGroups, productSetGroup, productSet, product, variant } = selectedProduct
    if (
      productSetGroups === undefined ||
      productSetGroup === undefined ||
      productSet === undefined ||
      product === undefined ||
      variant === undefined
    ) {
      sentry.warn(
        `assignSelectedProduct failed with productSetGroup: ${JSON.stringify(productSetGroup)}, productSet: ${JSON.stringify(
          productSet,
        )}, product: ${JSON.stringify(product)}, variant: ${JSON.stringify(variant)}`,
      )

      alertStore.setModalMessage(t('message.no_product'))

      const category = catalog.value?.find((c) =>
        c.__typename === 'BoundDeviceCategoryDto'
          ? c.boundDeviceType === state.categoryType
          : c.unboundDeviceType === state.categoryType,
      )

      if (category === undefined) {
        throw new Error(`cannot find category for type: ${state.categoryType}`)
      }

      return await initProductState(category)
    }

    const title = `${t(`series.${productSetGroup.groupKey}`)} (${t(`version.${product.version}`)})`

    if (state.categoryType === UnboundDeviceCategoryType.AquaStand) {
      const { data, error } = await useQuery({
        query: AquaStandPerspectiveDocument,
        variables: { sku: variant.sku, productSetHandle: productSet.handle },
      })

      if (error.value !== null || data.value === null) {
        const errorMessage = `failed to fetch AquaStandPerspective with sku: ${variant.sku}, productSetHandle: ${productSet.handle}, error: ${error.value?.toString() ?? ''}`
        sentry.error(errorMessage)
        throw new Error(errorMessage)
      }

      const { imageUrl, previewMaskUrl } = data.value.aquaStandPerspective

      const aquaStandVariant = {
        ...variant,
        aquaStandPerspective: { imageUrl, previewMaskUrl },
      }
      productState.value = {
        ...state,
        selectedProduct: {
          title,
          productSetGroups,
          productSetGroup,
          productSet,
          product,
          variant: aquaStandVariant,
        },
      }
    } else {
      productState.value = {
        ...state,
        selectedProduct: { title, productSetGroups, productSetGroup, productSet, product, variant },
      }
    }

    syncProductState(productState.value)

    return true
  }

  async function initSelectedProduct(
    productSets: readonly ProductSetFieldsFragment[],
    indicator?: NewProductIndicator,
  ): Promise<boolean> {
    const state = productState.value
    if (state === null) {
      throw new Error('should initProductState before initSelectedProduct')
    }

    const productSetGroups = groupProductSets(productSets)

    if (indicator !== undefined) {
      return await initSelectedProductByIndicator(state, productSetGroups, indicator)
    }

    return await initDefaultSelectedProduct(state, productSetGroups)
  }

  async function initDefaultSelectedProduct(
    state: ProductState,
    productSetGroups: readonly ProductSetGroup[],
  ): Promise<boolean> {
    const productSetGroup = productSetGroups[0]
    const productSet = productSetGroup?.productSets[0]
    const product = productSet?.products[0]
    const variant = product?.variants[0]

    return await assignSelectedProduct({
      state,
      selectedProduct: {
        productSetGroups,
        productSetGroup,
        productSet,
        product,
        variant,
      },
    })
  }

  async function initSelectedProductByIndicator(
    state: ProductState,
    productSetGroups: readonly ProductSetGroup[],
    indicator: NewProductIndicator,
  ): Promise<boolean> {
    const productSetGroup = productSetGroups.find((g) =>
      g.productSets.some((x) => x.products.some((y) => y.handle === indicator.productHandle)),
    )
    const productSet = productSetGroup?.productSets.find((x) =>
      x.products.some((y) => y.handle === indicator.productHandle),
    )
    const product = productSet?.products.find((p) => p.handle === indicator.productHandle)
    const variant =
      product?.variants.find((v) => v.color.handle === indicator.colorHandle) ??
      product?.variants[0]

    return await assignSelectedProduct({
      state,
      selectedProduct: {
        productSetGroups,
        productSetGroup,
        productSet,
        product,
        variant,
      },
    })
  }

  async function changeCategoryType(
    type: BoundDeviceCategoryType | UnboundDeviceCategoryType,
  ): Promise<void> {
    await initSingleton

    if (productState.value?.selectedProduct === null) {
      throw new Error('productState is locked')
    }

    const categories = catalog.value
    if (categories === null) {
      throw new Error('categories === null')
    }
    const category = categories.find((c) =>
      c.__typename === 'BoundDeviceCategoryDto'
        ? c.boundDeviceType === type
        : c.unboundDeviceType === type,
    )
    if (category === undefined) {
      throw new Error(`cannot find category for type: ${type}`)
    }
    await initProductState(category)
  }

  async function changeProductVariant(variantSku: string): Promise<void> {
    await initSingleton
    const state = productState.value

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (state === null || state.selectedProduct === null) {
      throw new Error('current state?.selectedProduct is null')
    }
    const variant = state.selectedProduct.product.variants.find((v) => v.sku === variantSku)
    if (variant === undefined) {
      throw new Error(`cannot find variant: ${variantSku}`)
    }

    await assignSelectedProduct({
      state,
      selectedProduct: {
        ...state.selectedProduct,
        variant,
      },
    })
  }

  async function changeProduct(productHandle: string): Promise<void> {
    await initSingleton
    const state = productState.value

    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (state === null || state.selectedProduct === null) {
      throw new Error('current state?.selectedProduct is null')
    }
    const productSetGroups = state.selectedProduct.productSetGroups
    const productSetGroup = productSetGroups.find((p) =>
      p.productSets.some((x) => x.products.some((y) => y.handle === productHandle)),
    )
    const productSet = productSetGroup?.productSets.find((p) =>
      p.products.some((y) => y.handle === productHandle),
    )
    const product = productSet?.products.find((p) => p.handle === productHandle)
    const variant = product?.variants[0]
    await assignSelectedProduct({
      state,
      selectedProduct: {
        productSetGroups,
        productSetGroup,
        productSet,
        product,
        variant,
      },
    })
  }

  async function assertCurrentBoundDeviceProductInfo(): Promise<{
    category: BoundDeviceCategoryFieldsFragment
    state: BoundDeviceProductState
  }> {
    await initSingleton
    const state = productState.value
    if (!typia.is<BoundDeviceCategoryType>(state?.categoryType)) {
      throw new Error('current state is not BoundDeviceProductState')
    }
    const category = catalog.value?.find((c) =>
      c.__typename === 'BoundDeviceCategoryDto' ? c.boundDeviceType === state.categoryType : false,
    )
    if (category?.__typename !== 'BoundDeviceCategoryDto') {
      throw new Error('cannot find category with current boundDeviceType in catalog')
    }
    return { state, category }
  }

  async function changeDeviceColor(colorHandle: string): Promise<void> {
    const { state, category } = await assertCurrentBoundDeviceProductInfo()
    const color = category.brands
      .find((b) => b.name === state.brandName)
      ?.devices.find((d) => d.handle === state.device.handle)
      ?.colors.find((c) => c.handle === colorHandle)

    if (color === undefined) {
      throw new Error(`cannot find color: ${colorHandle}`)
    }
    productState.value = { ...state, color }
    syncProductState(productState.value)
  }

  async function changeDevice(deviceHandle: string): Promise<void> {
    if (productState.value?.selectedProduct === null) {
      throw new Error('productState is locked')
    }

    const { category } = await assertCurrentBoundDeviceProductInfo()

    const brand = category.brands.find((b) => b.devices.some((d) => d.handle === deviceHandle))
    const device = brand?.devices.find((d) => d.handle === deviceHandle)
    const color = device?.colors[0] ?? null

    await initDevice({
      categoryType: category.boundDeviceType,
      brand,
      device,
      color,
    })
  }

  return {
    init,
    catalog: readonly(catalog),
    productState: readonly(productState),
    changeCategoryType,
    changeDeviceColor,
    changeProductVariant,
    changeProduct,
    changeDevice,
    changeByIndicator,
  }
})

function groupProductSets(productSets: readonly ProductSetFieldsFragment[]): ProductSetGroup[] {
  const productSetGroups = new Map<
    string,
    ProductSetGroup & { productSets: ProductSetFieldsFragment[] }
  >()

  for (const productSet of productSets) {
    const { groupKey, handle, thumbnailUrl } = productSet
    const key = groupKey ?? handle
    const productSetGroup = productSetGroups.get(key)
    if (productSetGroup === undefined) {
      productSetGroups.set(key, {
        groupKey: groupKey ?? handle,
        // TODO(ayson): 轉換成 i18n
        name: key,
        productSets: [productSet],
        thumbnailUrl,
      })
    } else {
      productSetGroup.productSets.push(productSet)
    }
  }

  return [...productSetGroups.values()]
}

function syncProductState(state: ProductState): void {
  if (typia.is<ProductStateWithSelectedProduct>(state)) {
    setProductQueryString(state)
    updateLocalStorage(state)
  }
}

function updateLocalStorage(state: ProductStateWithSelectedProduct): void {
  const recentProducts = getRecentProductsFromLocalStorage()
  if (typia.is<BoundDeviceProductState>(state)) {
    const origin = recentProducts?.boundDevice ?? {}
    const current: BoundDeviceProductIndicator = {
      categoryType: state.categoryType,
      deviceHandle: state.device.handle,
      deviceColorHandle: state.color?.handle ?? null,
      productHandle: state.selectedProduct.product.handle,
      colorHandle: state.selectedProduct.variant.color.handle,
    }
    setLocalStorage('recentProducts', {
      ...recentProducts,
      boundDevice: {
        ...origin,
        [state.categoryType]: current,
      },
      unboundDevice: recentProducts?.unboundDevice ?? {},
      recentCategoryType: state.categoryType,
    })
  } else {
    const origin = recentProducts?.unboundDevice ?? {}
    const current: UnboundDeviceProductIndicator = {
      categoryType: state.categoryType,
      productHandle: state.selectedProduct.product.handle,
      colorHandle: state.selectedProduct.variant.color.handle,
    }
    setLocalStorage('recentProducts', {
      ...recentProducts,
      boundDevice: recentProducts?.boundDevice ?? {},
      unboundDevice: {
        ...origin,
        [state.categoryType]: current,
      },
      recentCategoryType: state.categoryType,
    })
  }
}

function transformQueryString(queryString: QueryStringInfo): NewProductIndicator {
  return typia.is<BoundDeviceProductQueryString>(queryString)
    ? {
        categoryType: queryString.product_type,
        deviceHandle: queryString.device_handle,
        deviceColorHandle: queryString.device_color ?? null,
        productHandle: queryString.product_handle,
        colorHandle: queryString.product_color,
      }
    : {
        categoryType: queryString.product_type,
        productHandle: queryString.product_handle,
        colorHandle: queryString.product_color,
      }
}
