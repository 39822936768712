import { storeConfig } from '@/locale'

import { sentry } from './sentry'

type GA4Event =
  | GA4AddToCartEvent
  | GA4FilterEvent
  | GA4PersonalizationClickEvent
  | GA4PersonalizationServiceSelectionEvent
  | GA4ViewCartEvent

interface GA4AddToCartEvent {
  ecommerce: {
    currency: string
    value: number
  }
  event: 'ga4_add_to_cart'
}

export interface GA4ecommerceItem {
  brand: string
  collabHandle: string
  collabName: string
  designName: string
  designType: 'collab' | 'customized' | 'original'
  device: string
  discount: number
  id: string
  index: number
  originalVariantID: number[]
  price: number
  productLine: string
  productLineName: string
  quantity: number
  title: string
  variantTitle: string
}

// 帶入的文案基本上都會用英文帶入
interface GA4PersonalizationClickEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_location: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  button_text: string
  event: 'personalization_click'
}

// 首頁底下服務的點擊個人化跳轉會用中文處理
interface GA4PersonalizationServiceSelectionEvent {
  background: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  background_title: string
  event: 'personalization_service_selection'
  service: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_title: string
}

interface GA4ViewCartEvent {
  event: 'view_cart'
}

interface GA4FilterEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  category_selected: string
  event: 'filter'
  // eslint-disable-next-line @typescript-eslint/naming-convention
  filter_type: 'artist_filter' | 'artist_sorting' | 'artwork_filter' | 'artwork_sorting'
}

export function pushAddToCartEvents(price: number): void {
  const event: GA4AddToCartEvent = {
    event: 'ga4_add_to_cart',
    ecommerce: {
      value: price,
      currency: storeConfig.currencyUnit,
    },
  }
  pushDataLayer(event)

  /** 目前沒有 content id 所以給空 array */
  const contents: ContentItem[] = []
  const tikTokAndMetaEvent = {
    // 目前前端沒接收 shopify 產品的 title，所以暫時給空字串
    content_name: '',
    content_type: 'product',
    currency: storeConfig.currencyUnit,
    values: price,
    contents,
  }
  pushTikTok('AddToCart', tikTokAndMetaEvent)
  pushMeta('AddToCart', tikTokAndMetaEvent)
  pushTwitter('AddToCart', {
    contents,
    conversion_id: null,
    currency: storeConfig.currencyUnit,
    // 可能給空字串或 null 都沒差，所以我先照文件上面的給空字串
    email_address: '',
    value: price,
  })
}

export function pushGA4ButtonClick(buttonKey: string): void {
  const location =
    window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '')
  const event: GA4PersonalizationClickEvent = {
    event: 'personalization_click',
    button_text: buttonKey,
    button_location: location,
  }
  pushDataLayer(event)
}

export function pushGA4ServiceSelection({
  service,
  serviceTitle,
  background,
  backgroundTitle,
}: {
  background: string
  backgroundTitle: string
  service: string
  serviceTitle: string
}): void {
  const event: GA4PersonalizationServiceSelectionEvent = {
    event: 'personalization_service_selection',
    service,
    service_title: serviceTitle,
    background,
    background_title: backgroundTitle,
  }
  pushDataLayer(event)
}

export function pushGA4ViewCart(): void {
  const event: GA4ViewCartEvent = {
    event: 'view_cart',
  }
  pushDataLayer(event)
}

export function pushGA4Filter(
  filterType: GA4FilterEvent['filter_type'],
  categorySelected: string,
): void {
  const event: GA4FilterEvent = {
    event: 'filter',
    filter_type: filterType,
    category_selected: categorySelected,
  }
  pushDataLayer(event)
}

function pushDataLayer(event: GA4Event): void {
  if (!('dataLayer' in window)) {
    window.dataLayer = []
  }
  if (Array.isArray(window.dataLayer)) {
    window.dataLayer.push(event)
  } else {
    sentry.error('dataLayer is not Array', {
      event,
    })
  }
}

// docs: https://docs.google.com/spreadsheets/d/1IKluXtm0R3x3C5utU_tLsqYJM3vBBMA-/edit

type EventName = 'AddToCart'

interface ContentItem {
  id: string
  quantity: number
}

interface TikTokAddToCartEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_name: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_type: string
  contents: ContentItem[]
  currency: string
  values: number
}
type TikTokEvent<Name extends EventName> = Record<string, unknown> &
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AddToCart: TikTokAddToCartEvent
  }[Name]
function pushTikTok<Name extends EventName>(eventName: Name, event: TikTokEvent<Name>): void {
  // event_id 現在不用傳送
  window.ttq?.track(eventName, event)
}

interface MetaAddToCartEvent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_name: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_type: string
  contents: ContentItem[]
  currency: string
  values: number
}
type MetaEvent<Name extends EventName> = Record<string, unknown> &
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AddToCart: MetaAddToCartEvent
  }[Name]
function pushMeta<Name extends EventName>(eventName: Name, event: MetaEvent<Name>): void {
  window.fbq('track', eventName, event)
}

interface TwitterAddToCartEvent {
  contents: ContentItem[]
  // eslint-disable-next-line @typescript-eslint/naming-convention
  conversion_id: null
  currency: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  email_address: string
  value: number
}
type TwitterEvent<Name extends EventName> = Record<string, unknown> &
  {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AddToCart: TwitterAddToCartEvent
  }[Name]
function pushTwitter<Name extends EventName>(eventName: Name, event: TwitterEvent<Name>): void {
  const eventId: Record<EventName, string> = {
    AddToCart: 'tw-oc5ly-oc5m1',
  }
  window.twq('event', eventId[eventName], event)
}
