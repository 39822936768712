<template>
  <teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="visible"
        class="fixed inset-0 z-300 overflow-hidden"
        :class="{
          'bg-transparent-70': !notShowBlackCover,
        }"
        @click="closeBackdrop($event)"
      >
        <div
          class="h-100vh flex items-center justify-center"
          :style="{ 'max-height': '-webkit-fill-available' }"
        >
          <transition
            enter-active-class="transition ease-out duration-300 transform"
            enter-from-class="opacity-0 translate-y-10 scale-60"
            enter-to-class="opacity-100 translate-y-0 scale-100"
          >
            <!--        modal -->
            <div
              v-if="visible"
              ref="modal"
              class="relative m-20px max-h-80vh max-w-600px flex flex-col overflow-hidden rounded-10px bg-white pb-16px text-center shadow-modal"
              :style="{ width: dialogWidth }"
            >
              <div class="h-40px flex justify-end p-8px">
                <i
                  v-if="!hideCloseButton"
                  class="icon-close cursor-pointer text-24px"
                  @click="
                    () => {
                      pushGA4ButtonClick(
                        `modal dialog close${componentName !== '' ? `: ${componentName}` : ''}`,
                      )
                      closeModal()
                    }
                  "
                />
              </div>
              <div
                v-if="$slots['header']"
                class="flex items-center justify-center title-14-h20-b text-primary-100"
              >
                <slot name="header" />
              </div>
              <div
                v-if="$slots['content']"
                class="my-16px overflow-y-scroll px-24px content-16-h24-r text-primary-100 scrollbar"
              >
                <slot name="content" />
              </div>
              <div
                v-if="$slots['footer']"
                class="modal-footer my-8px flex justify-center content-12-h16-r"
              >
                <slot name="footer" />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
import { useTemplateRef, watch } from 'vue'

import { pushGA4ButtonClick } from '@/utils/googleAnalytics'

const props = withDefaults(
  defineProps<{
    /**
     * 目前主要是設定給 GA 紀錄資料用的
     */
    componentName?: string
    dialogWidth?: string
    dismissibleMask?: boolean
    hideCloseButton?: boolean
    notShowBlackCover?: boolean
    visible?: boolean
  }>(),
  {
    visible: false,
    dismissibleMask: false,
    dialogWidth: '',
    notShowBlackCover: false,
    hideCloseButton: false,
    componentName: '',
  },
)

const emit = defineEmits<{
  hide: []
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'update:visible': [visible: boolean]
}>()

watch(
  () => props.visible,
  (newValue) => {
    if (newValue) document.querySelector('body')?.classList.add('overflow-hidden')
    else {
      document.querySelector('body')?.classList.remove('overflow-hidden')
    }
  },
)

const modal = useTemplateRef<HTMLElement>('modal')

function closeModal(): void {
  emit('update:visible', false)
  emit('hide')
}

function closeBackdrop(e: Event): void {
  if (!props.dismissibleMask || modal.value == null || e.composedPath().includes(modal.value)) {
    return
  }
  closeModal()
}
</script>

<style scoped>
.modal-footer :deep(button) {
  @apply min-h-36px min-w-100px px-12px py-10px flex items-center justify-center;
}

.modal-footer :deep(button:first-child) {
  @apply ml-32px;
}

.modal-footer :deep(button:last-child) {
  @apply mr-32px;
}

.modal-footer :deep(button:not(:first-child)) {
  @apply ml-36px;
}
</style>
