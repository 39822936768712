import typia from 'typia'

import {
  type BoundDeviceCategoryFieldsFragment,
  type BoundDeviceCategoryType,
  type ColorTypeEnum,
  type DesignGroupType,
  type StockStatus,
} from '@/graphql/creator-platform/generated'
import {
  type BoundDeviceProductState,
  type CategoryType,
  type ProductStateWithSelectedProduct,
} from '@/store/newProductStore.type'

interface BaseCategoryProperty {
  data: null | {
    name: string
  }
  isSingleOption: boolean
  onClick?: () => Promise<void> | void
  title: string
}
interface ColorCategoryProperty extends BaseCategoryProperty {
  data: null | {
    colorType: ColorTypeEnum
    name: string
    stockStatus?: StockStatus
    value: string
  }
  type: 'color'
}
interface TextCategoryProperty extends BaseCategoryProperty {
  type: 'text'
}
export type CategoryProperty = ColorCategoryProperty | TextCategoryProperty

export interface ProductSetGroup {
  groupKey: string
  productSets:
    | []
    | Array<{
        designGroupType: DesignGroupType
        handle: string
        productOption: Array<{
          handle: string
          version: string
        }>
      }>
}
interface ProductItem {
  productSetGroups: Array<{
    productSetGroup: ProductSetGroup
    thumbnail: string
  }>
  title: string
}
interface ColorItem {
  onClick: (e?: string) => Promise<void> | void
  options: Array<{
    colorDisplay: string
    colorType: ColorTypeEnum
    name: string
    stockStatus?: StockStatus
    // product sku
    value: string
  }>
  title: string
  type: 'color'
}
interface ExpandableItem {
  onClick: (e?: string) => Promise<void> | void
  options: Array<{ name: string; value: string }>
  title: string
  type: 'expandableItem'
}
interface StandaloneItem {
  onClick: (e?: string) => Promise<void> | void
  options: Array<{ name: string; value: string }>
  title: string
  type: 'standaloneItem'
}
export type StackItem = ColorItem | ExpandableItem | ProductItem | StandaloneItem

export type OnOpenForProductPage =
  | 'selectBrand'
  | 'selectDeviceColor'
  | 'selectProductSetGroup'
  | 'selectVariant'

// todo(chili): 與 store 整合
export const isBoundDeviceCategory = typia.createIs<BoundDeviceCategoryFieldsFragment>()
export const isBoundDeviceState = typia.createIs<BoundDeviceProductState>()
export const isProductItem = typia.createIs<ProductItem>()
export const isColorItem = typia.createIs<ColorItem>()
export const isProductStateWithSelectedProduct = typia.createIs<ProductStateWithSelectedProduct>()
export const isCategoryType = typia.createIs<CategoryType>()
export const isBoundDeviceCategoryType = typia.createIs<BoundDeviceCategoryType>()
