import typia from 'typia'

import {
  type BoundDeviceCategoryType,
  type UnboundDeviceCategoryType,
} from '@/graphql/creator-platform/generated'
import {
  type BoundDeviceProductIndicator,
  type UnboundDeviceProductIndicator,
} from '@/store/newProductStore.type'

// 這邊定義整個專案會用到的 LocalStorage kev, value type
export interface LocalStorage {
  readonly recentProducts: {
    readonly recentCategoryType: BoundDeviceCategoryType | UnboundDeviceCategoryType

    readonly boundDevice: Partial<Record<BoundDeviceCategoryType, BoundDeviceProductIndicator>>

    readonly unboundDevice: Partial<
      Record<UnboundDeviceCategoryType, UnboundDeviceProductIndicator>
    >
  }
}

export function getRecentProductsFromLocalStorage(): LocalStorage['recentProducts'] | null {
  const data = localStorage.getItem('recentProducts')
  if (data === null) {
    return null
  }
  const j = JSON.parse(data) as unknown
  return typia.is<LocalStorage['recentProducts']>(j) ? j : null
}

export function setLocalStorage<Key extends keyof LocalStorage>(
  k: Key,
  v: LocalStorage[Key],
): void {
  localStorage.setItem(k, JSON.stringify(v))
}
