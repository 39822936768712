import {
  addLineItems,
  appendDiscountCode,
  type Cart,
  getCart,
  type Product,
} from '@evolutivelabs/rhinoshield-shopify-client'
import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'

export const useCartStore = defineStore('cart', () => {
  const cart = ref<Cart | null>(null)
  const totalQuantity = computed(() => cart.value?.totalQuantity ?? 0)

  async function initProcess(): Promise<void> {
    cart.value = await getCart()
  }
  let initSingleton: null | Promise<void> = null
  async function init(): Promise<void> {
    if (initSingleton == null) {
      initSingleton = initProcess()
    }
    await initSingleton
  }
  void init()

  async function addProductToCart(products: Product[]): Promise<void> {
    await init()
    cart.value = await addLineItems(products)
  }

  async function addDiscountCode(discountCode: string): Promise<void> {
    await init()
    cart.value = await appendDiscountCode(discountCode)
  }

  return { totalQuantity: readonly(totalQuantity), addProductToCart, addDiscountCode }
})
