import { useQuery } from 'villus'

import {
  type ProductSetFieldsFragment,
  ProductSetsByCategoryTypeDocument,
  ProductSetsByDeviceHandleDocument,
  type UnboundDeviceCategoryType,
} from '@/graphql/creator-platform/generated'
import { storeConfig } from '@/locale'
import { sentry } from '@/utils/sentry'

const boundDeviceProductSetsCache = new Map<string, readonly ProductSetFieldsFragment[]>()

export async function getBoundDeviceProductSets(
  deviceHandle: string,
): Promise<readonly ProductSetFieldsFragment[]> {
  const sets = boundDeviceProductSetsCache.get(deviceHandle)
  if (sets !== undefined) return sets

  const { data, error } = await useQuery({
    query: ProductSetsByDeviceHandleDocument,
    variables: { deviceHandle, locale: storeConfig.locale },
  })

  if (error.value !== null || data.value === null) {
    const errorMessage = `getBoundDeviceProductSets failed with deviceHandle: ${deviceHandle}, error: ${error.value?.toString() ?? ''}`
    sentry.error(errorMessage)
    throw new Error(errorMessage)
  }

  boundDeviceProductSetsCache.set(deviceHandle, data.value.productSetsByDevice)

  return data.value.productSetsByDevice
}

const unboundDeviceProductSetsCache = new Map<
  UnboundDeviceCategoryType,
  readonly ProductSetFieldsFragment[]
>()
export async function getUnboundDeviceProductSets(
  type: UnboundDeviceCategoryType,
): Promise<readonly ProductSetFieldsFragment[]> {
  const sets = unboundDeviceProductSetsCache.get(type)
  if (sets !== undefined) return sets

  const { data, error } = await useQuery({
    query: ProductSetsByCategoryTypeDocument,
    variables: { type, locale: storeConfig.locale },
  })

  if (error.value !== null || data.value === null) {
    const errorMessage = `getUnboundDeviceProductSets failed with type: ${type}, error: ${error.value?.toString() ?? ''}`
    sentry.error(errorMessage)
    throw new Error(errorMessage)
  }

  unboundDeviceProductSetsCache.set(type, data.value.productSetsByCategoryType)

  return data.value.productSetsByCategoryType
}
