// cached version may not set image.crossOrigin = 'anonymous' and cause CORS problem
import { ImageError, sentry } from '@/utils/sentry'

export async function loadImage(url: string, disableCache = false): Promise<HTMLImageElement> {
  const image = new Image()
  image.crossOrigin = 'anonymous'
  const urlObj = new URL(url)
  if (disableCache && ['http:', 'https:'].includes(urlObj.protocol)) {
    urlObj.searchParams.set('do-not-use-cached-resource', Date.now().toString())
  }
  image.src = urlObj.toString()
  return await new Promise((resolve, reject) => {
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', () => {
      // 試試看這樣有沒有捕獲到錯誤
      sentry.error(new ImageError('load image failed'))
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      reject('load image failed')
    })
  })
}
