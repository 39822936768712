import { defineStore } from 'pinia'
import { useQuery } from 'villus'
import { readonly, ref } from 'vue'

import { DesignGroupsDocument, type DesignGroupType } from '@/graphql/creator-platform/generated'
import { sentry } from '@/utils/sentry'
import { type Rect } from '@/utils/type.util'

export interface DesignGroup {
  readonly designRect: Rect
  readonly postDesignLayers: string[]
  readonly preDesignLayers: string[]
  readonly previewMaskUrl: string
  readonly printMaskUrl: string
  readonly thumbnailUrl: string
  readonly type: DesignGroupType
}

export const useDesignGroupStore = defineStore('designGroup', () => {
  const designGroups = ref<DesignGroup[]>([])

  async function initProcess(): Promise<void> {
    const query = await useQuery({ query: DesignGroupsDocument })
    designGroups.value = query.data.value?.designGroups ?? []
  }

  let initSingleton: null | Promise<void> = null
  async function init(): Promise<void> {
    if (initSingleton == null) {
      initSingleton = initProcess()
    }
    await initSingleton
  }

  async function getDesignGroup(type: DesignGroupType): Promise<DesignGroup> {
    await init()
    const designGroup = designGroups.value.find((g) => g.type === type)
    if (designGroup === undefined) {
      sentry.warn(`can not find designGroup for ${type}`)
      throw new Error(`cannot find design group: ${type}`)
    }
    return designGroup
  }

  return { designGroups: readonly(designGroups), init, getDesignGroup }
})
