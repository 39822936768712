import { type AllowedComponentProps, type Component, type VNodeProps } from 'vue'

import {
  BoundDeviceCategoryType,
  UnboundDeviceCategoryType,
} from '@/graphql/creator-platform/generated'
import { type CategoryType } from '@/store/newProductStore.type'

export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === 'object' && x !== null
}

export function isArrayOf<T>(x: unknown, isT: (x: unknown) => boolean): x is T[] {
  return Array.isArray(x) && x.every((x) => isT(x))
}

// 在 '@/data' 裡面有重複，但因為 product 會拆掉，先不從那邊 import
export type ArrayElement<T> = T extends Array<infer P> ? P : never

export interface Rect {
  readonly height: number
  readonly width: number
  readonly x: number
  readonly y: number
}

export interface PositionedImage {
  readonly rect: Rect
  readonly url: string
}

export function isNotNull<T>(x: null | T): x is T {
  return x !== null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PropTypeOf<C extends Component> = C extends new (...args: any) => any
  ? Omit<InstanceType<C>['$props'], keyof AllowedComponentProps | keyof VNodeProps>
  : never

export const productTypeToggleSwitchConfig: Record<
  CategoryType,
  { readonly checked: string; readonly unchecked: string }
> = {
  [BoundDeviceCategoryType.PhoneCase]: {
    checked: 'backplate',
    unchecked: 'backplate-off',
  },
  [BoundDeviceCategoryType.AirPodsCase]: {
    checked: 'airpods',
    unchecked: 'airpods-off',
  },
  [UnboundDeviceCategoryType.Grip]: {
    checked: 'grip',
    unchecked: 'grip-off',
  },
  [BoundDeviceCategoryType.IPadCase]: {
    checked: 'ipad',
    unchecked: 'ipad-off',
  },
  [BoundDeviceCategoryType.AirTagCase]: {
    checked: 'airtag',
    unchecked: 'airtag-off',
  },
  [UnboundDeviceCategoryType.AquaStand]: {
    checked: 'aquastand',
    unchecked: 'aquastand-off',
  },
}
