/**
 * 封裝 design-canvas 的 design store，統一處理客製化程度以及作品上下架檢查以及總價處理等
 * */

import {
  AppendElementCommand,
  type BarcodeElementData,
  CompositeCommand,
  createBarcodeElement,
  createImageBackground,
  createPhotoElement,
  createPlainColorBackground,
  createStickerElement,
  type DesignCommand,
  type ImageBackgroundData,
  type ImmutableImageBackground,
  type ImmutableStickerElement,
  isImageBackground,
  isPlainColorBackground,
  isStickerElement,
  type PhotoElementData,
  type PointOrVector2D,
  type Rect,
  RemoveElementCommand,
  type StickerElementData,
  UpdateBackgroundCommand,
  useDesignStore,
} from '@evolutivelabs/amuse-design-canvas'
import { defineStore, storeToRefs } from 'pinia'
import { computed, shallowRef, watch } from 'vue'

import { CASE_MATERIAL_IMAGE_SIZE } from '@/components/designArea/utils'
import {
  type CollageWithDesignInfoCommonFieldsFragment,
  type DesignGroupType,
} from '@/graphql/creator-platform/generated'
import { convertArtworkI18ns } from '@/utils/artworkI18ns'
import { loadImage } from '@/utils/loadImage'

import { useDesignGroupStore } from './designGroupStore'
import { useNewProductStore } from './newProductStore'
import { type PriceLineItems, type Quantity, type VariantId } from './pricePlan'
import { usePricePlansStore } from './pricePlansStore'

interface Chargeable {
  readonly background: ImmutableImageBackground | null
  readonly stickerGroups: ImmutableStickerElement[]
}

export const useDittoDesignStore = defineStore('dittoDesignStore', () => {
  const designStore = useDesignStore()
  const pricePlansStore = usePricePlansStore()
  const { selectElement, clearHistory, clear, updateBackground, importConfig } = designStore
  const { design } = storeToRefs(designStore)

  const priceLineItems = shallowRef<null | PriceLineItems>(null)

  const chargeable = computed((): Chargeable => {
    const { background, elements } = design.value
    const stickerGroups: ImmutableStickerElement[] = []
    for (const element of elements.values()) {
      if (isStickerElement(element) && element.isVisible) {
        stickerGroups.push(element)
      }
    }
    return {
      background:
        background !== null && isImageBackground(background) && background.isVisible
          ? background
          : null,
      stickerGroups,
    }
  })

  watch(
    chargeable,
    async (value, oldValue) => {
      if (
        oldValue !== undefined &&
        oldValue.background?.id === value.background?.id &&
        oldValue.stickerGroups.every((stickerGroup) =>
          value.stickerGroups.some((v) => v.groupId === stickerGroup.groupId),
        ) &&
        value.stickerGroups.every((stickerGroup) =>
          oldValue.stickerGroups.some((v) => v.groupId === stickerGroup.groupId),
        )
      ) {
        return
      }

      priceLineItems.value = null

      const { background, stickerGroups } = value
      const ids = [...new Set(stickerGroups.map((v) => v.groupId))]
      if (background !== null) {
        ids.push(background.id)
      }

      const priceInfoMap = await pricePlansStore.getPriceByArtworkIds(ids)

      const accumulator = { price: 0, compareAtPrice: 0 }
      const lineItems = new Map<VariantId, Quantity>()

      if (background !== null) {
        const p = priceInfoMap.get(background.id)
        if (p === undefined) {
          designStore.execute(new UpdateBackgroundCommand(null), { ignoreHistory: false })
        } else {
          accumulator.price = p.price
          accumulator.compareAtPrice = p.compareAtPrice

          lineItems.set(p.variantId, (lineItems.get(p.variantId) ?? 0) + 1)
        }
      }

      const stickerGroupIds = new Set<string>()
      for (const stickerGroup of stickerGroups) {
        const p = priceInfoMap.get(stickerGroup.groupId)
        if (p === undefined) {
          designStore.execute(new RemoveElementCommand(stickerGroup), {
            ignoreHistory: false,
          })
        } else {
          if (!stickerGroupIds.has(stickerGroup.groupId)) {
            accumulator.price += p.price
            accumulator.compareAtPrice += p.compareAtPrice
            lineItems.set(p.variantId, (lineItems.get(p.variantId) ?? 0) + 1)
            stickerGroupIds.add(stickerGroup.groupId)
          }
        }
      }
      priceLineItems.value = { ...accumulator, lineItems }
    },
    { immediate: true },
  )

  const productStore = useNewProductStore()
  const viewableRect = computed(
    () => productStore.productState?.selectedProduct?.product.viewableRect ?? null,
  )

  async function addPhoto(data: Omit<PhotoElementData, 'position'>): Promise<void> {
    await productStore.init()
    if (viewableRect.value === null) {
      throw new Error('viewableRect is null')
    }

    const { position, scaleRatio } = calculateImageParams(
      data.width,
      data.height,
      viewableRect.value,
    )
    designStore.appendElement(createPhotoElement({ ...data, position, scaleRatio }))
  }

  async function addBarcode(data: Pick<BarcodeElementData, 'imageUrl' | 'name'>): Promise<void> {
    await productStore.init()
    if (viewableRect.value === null) {
      throw new Error('viewableRect is null')
    }
    const { width, height } = await loadImage(data.imageUrl)
    const { position, scaleRatio } = calculateImageParams(width, height, viewableRect.value)
    designStore.appendElement(
      createBarcodeElement({ ...data, position, scaleRatio, width, height }),
    )
  }

  async function addSticker(
    data: Omit<StickerElementData, 'height' | 'position' | 'width'>,
  ): Promise<void> {
    await productStore.init()
    if (viewableRect.value === null) {
      throw new Error('viewableRect is null')
    }

    const { width, height } = await loadImage(data.imageUrl)
    const { scaleRatio, position } = calculateImageParams(width, height, viewableRect.value)
    designStore.appendElement(
      createStickerElement({
        ...data,
        width,
        height,
        position,
        scaleRatio,
      }),
    )
  }

  const { getDesignGroup } = useDesignGroupStore()

  async function updateImageBackground(
    data: Omit<ImageBackgroundData, 'backgroundType' | 'height' | 'position' | 'width'>,
    designGroupType: DesignGroupType,
  ): Promise<void> {
    const { designRect } = await getDesignGroup(designGroupType)

    designStore.updateBackground(
      createImageBackground({
        id: data.id,
        imageUrl: data.imageUrl,
        creatorId: data.creatorId,
        name: data.name,
        width: designRect.width,
        height: designRect.height,
        position: {
          x: designRect.x,
          y: designRect.y,
        },
        isVisible: true,
      }),
    )
  }

  function deleteBackground(): void {
    updateBackground(null)
  }

  function updatePlainColorBackground(colorCode: string): void {
    if (colorCode === TRANSPARENT_COLOR_CODE) {
      deleteBackground()
    } else {
      updateBackground(
        createPlainColorBackground({
          position: { x: 0, y: 0 },
          width: CASE_MATERIAL_IMAGE_SIZE,
          height: CASE_MATERIAL_IMAGE_SIZE,
          colorCode,
          isVisible: true,
          name: 'plain color background',
        }),
      )
    }
  }

  const backgroundPlainColor = computed((): string => {
    const element = design.value.background
    if (element === null || !isPlainColorBackground(element)) {
      return ''
    }
    return element.colorCode
  })

  async function convertCollageAndAppendToStore(
    collage: CollageWithDesignInfoCommonFieldsFragment,
    clearHistory = true,
  ): Promise<void> {
    const designStore = useDesignStore()
    const commands: DesignCommand[] = []
    if (clearHistory) {
      designStore.clear()
    } else {
      designStore.clearDesign()
    }
    const background =
      collage.background === null || collage.background === undefined
        ? collage.background
        : convertArtworkI18ns(collage.background)
    if (typeof background?.imageUrl === 'string') {
      const designGroupStore = useDesignGroupStore()
      const designGroup = await designGroupStore.getDesignGroup(collage.designGroupType)
      commands.push(
        new UpdateBackgroundCommand(
          createImageBackground({
            id: background.id,
            width: designGroup.designRect.width,
            height: designGroup.designRect.height,
            creatorId: collage.creator.id,
            position: {
              x: designGroup.designRect.x,
              y: designGroup.designRect.y,
            },
            imageUrl: background.imageUrl,
            name: background.name,
            isVisible: collage.backgroundVisible,
          }),
        ),
      )
    }
    const stickerOnCollages = collage.stickerOnCollages.sort((a, b) => a.zIndex - b.zIndex)
    for (const stickerOnCollage of stickerOnCollages) {
      const {
        x,
        y,
        sticker,
        visible,
        angleRadian,
        size,
        numStackColumns,
        numStackRows,
        stackGapRatio,
        stackOffsetRatio,
      } = stickerOnCollage
      commands.push(
        new AppendElementCommand(
          createStickerElement({
            stickerId: sticker.id,
            groupId: sticker.group.id,
            creatorId: collage.creator.id,
            isLocked: false,
            isVisible: visible,
            position: {
              x,
              y,
            },
            width: size,
            height: size,
            angleRadian,
            scaleRatio: 1,
            imageUrl: sticker.imageUrl,
            thumbnailUrl: sticker.thumbnailUrl ?? sticker.imageUrl,
            name: sticker.group.name,
            numStackColumns,
            numStackRows,
            stackGapRatio,
            stackOffsetRatio,
          }),
        ),
      )
    }

    designStore.execute(new CompositeCommand(commands))
  }

  return {
    updatePlainColorBackground,
    addSticker,
    addBarcode,
    addPhoto,
    selectElement,
    clearHistory,
    updateImageBackground,
    deleteBackground,
    backgroundPlainColor,
    clear,
    design,
    // TODO 檢查上下架和客製化程度和 designGroupType
    importConfig,
    convertCollageAndAppendToStore,
    priceLineItems,
  }
})

function calculateImageParams(
  width: number,
  height: number,
  rect: Rect,
): { position: PointOrVector2D; scaleRatio: number } {
  const expectedMaxWidth = rect.width * 0.6
  const expectedMaxHeight = rect.height * 0.6
  const widthScale = width > expectedMaxWidth ? expectedMaxWidth / width : 1
  const heightScale = height > expectedMaxHeight ? expectedMaxHeight / height : 1
  const scaleRatio = Math.min(widthScale, heightScale)
  const position = {
    x: (rect.width - width * scaleRatio) / 2 + rect.x,
    y: (rect.height - height * scaleRatio) / 2 + rect.y,
  }
  return { scaleRatio, position }
}

export const TRANSPARENT_COLOR_CODE = 'transparent'
