import { type PositionedImage } from '@/utils/type.util'

export const CASE_MATERIAL_IMAGE_SIZE = 2000

// 要計算一行可以放幾個元素，但元素中間會有間距
// 這邊我不知道怎麼算比較好，只好用迴圈算 XD，如果有比較好的算法最好改掉
export function calculateMaxRowNumber(
  containerWidth: number,
  clientWidth: number,
  spacing: number,
): number {
  const maxRowNumber = Math.floor(containerWidth / clientWidth)
  let realRowNumber = 0
  for (let i = maxRowNumber; i >= 0; i--) {
    const expectWidth = clientWidth * i + spacing * (i - 1)
    if (containerWidth >= expectWidth) {
      realRowNumber = i
      break
    }
  }
  return realRowNumber
}

export function toFullSizeImage(url: string): PositionedImage {
  return {
    url,
    rect: { x: 0, y: 0, width: CASE_MATERIAL_IMAGE_SIZE, height: CASE_MATERIAL_IMAGE_SIZE },
  }
}
