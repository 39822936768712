import { type InitOptions } from 'i18next'
import * as i18next from 'i18next'

import { Locale } from '@/graphql/creator-platform/generated'
import { getCurrentIsProduction, storeConfig } from '@/locale'
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fr from '@/locales/fr.json'
import jp from '@/locales/jp.json'
import kr from '@/locales/kr.json'
import th from '@/locales/th.json'
import tw from '@/locales/tw.json'

// 預設的語言

const translations: Record<Locale, object> = {
  [Locale.Tw]: tw,
  [Locale.Jp]: jp,
  [Locale.Th]: th,
  [Locale.Asia]: en,
  [Locale.Io]: en,
  [Locale.Eu]: en,
  [Locale.Uk]: en,
  [Locale.Fr]: fr,
  [Locale.De]: de,
  [Locale.Es]: es,
  [Locale.Kr]: kr,
}

const resources: InitOptions['resources'] = Object.fromEntries(
  Object.entries(translations)
    .map(([key, data]) => ({ key, translation: data }))
    .map(({ key, translation }) => [key, { translation }]),
)

i18next.use({
  type: 'postProcessor',
  name: 'aTagTargetBlank',
  process: function (value: string): string {
    return value.replaceAll('<a ', '<a target="_blank" ')
  },
})

void i18next.init({
  lng: storeConfig.locale,
  resources,
  fallbackLng: Locale.Io,
  debug: !getCurrentIsProduction(),
})

export { default } from 'i18next'
