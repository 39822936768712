import { type App, type DirectiveBinding } from 'vue'

export function useClickOutsideDirective(app: App): void {
  let registry: Array<{ readonly action: () => void; readonly el: Element }> = []
  document.addEventListener(
    'click',
    (event: Event) => {
      const target = event.target
      if (!(target instanceof Element)) return
      for (const { el, action } of registry) {
        if (!el.contains(target)) action()
      }
    },
    {
      // 若註冊元素的子元素在點擊後消失，會造成誤判，因此在 capture 階段就進行判斷
      capture: true,
    },
  )
  app.directive('click-outside', {
    mounted(el: Element, binding: DirectiveBinding<() => void>) {
      registry.push({ el, action: binding.value })
    },
    unmounted(el: HTMLElement) {
      registry = registry.filter((x) => x.el !== el)
    },
  })
}
