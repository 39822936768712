import { type Location } from '@/graphql/creator-platform/generated'
import { backendLocation } from '@/graphql/utils'

interface I18n {
  description?: string
  location: Location
  name: string
}
export interface StickerGroupI18n {
  description?: string
  name: string
  stickerGroupI18ns: I18n[]
}
export interface BackgroundI18n {
  backgroundI18ns: I18n[]
  description?: string
  name: string
}
export interface CollageI18n {
  collageI18ns: I18n[]
  description?: string
  name: string
}
type ArtworkI18n = BackgroundI18n | CollageI18n | StickerGroupI18n

export type ConvertArtworkI18ns<Item extends ArtworkI18n> = Omit<
  Item,
  'backgroundI18ns' | 'collageI18ns' | 'stickerGroupI18ns'
>

/**
 * TODO(benson): 後端支援後前端即可不用自己過濾
 */
export function convertArtworkI18ns<Item extends ArtworkI18n>(
  item: Item,
): ConvertArtworkI18ns<Item> {
  let i18ns: I18n[]
  if ('stickerGroupI18ns' in item) {
    i18ns = item.stickerGroupI18ns
  } else if ('backgroundI18ns' in item) {
    i18ns = item.backgroundI18ns
  } else {
    i18ns = item.collageI18ns
  }
  const i18n: I18n | undefined = i18ns.find((item: I18n) => item.location === backendLocation)

  if (i18n !== undefined) {
    item.name = i18n.name
    if ('description' in item && 'description' in i18n && i18n.description !== '') {
      item.description = i18n.description
    }
  }
  return item
}
