import { defineStore } from 'pinia'
import { useQuery } from 'villus'

import {
  type CollageCommonFieldsFragment,
  PricePlansDocument,
  PriceSkuDocument,
} from '@/graphql/creator-platform/generated'
import { backendLocation } from '@/graphql/utils'
import { storeConfig } from '@/locale'
import { sentry } from '@/utils/sentry'

import { type PriceInfo, type PricePlan } from './pricePlan'

const freePricePlanSku = 'FREE_ITEM'

export const usePricePlansStore = defineStore('pricePlans', () => {
  const pricePlanMap = new Map<string, PricePlan>()

  let initSingleton: null | Promise<void> = null
  async function init(): Promise<void> {
    if (initSingleton == null) {
      initSingleton = initProcess()
    }
    await initSingleton
  }
  async function initProcess(): Promise<void> {
    const { data, error } = await useQuery({
      query: PricePlansDocument,
      variables: {
        locale: storeConfig.locale,
      },
    })
    if (error.value !== null || data.value === null) {
      const errorMessage = `init price plans failed with error: ${error.value?.toString() ?? ''}`
      sentry.error(errorMessage)
      throw new Error(errorMessage)
    }
    for (const { sku, amount: price, compareAtAmount: compareAtPrice, variantId } of data.value
      .pricePlansForDitto) {
      pricePlanMap.set(sku, {
        price,
        compareAtPrice,
        variantId: sku === freePricePlanSku ? null : variantId,
      })
    }
  }

  // TODO(ayson): 這個 map 是因為 design canvas 的付費元素沒有帶著 sku，只有 artworkId，
  // 導致 ditto 專案需要用 artworkId query 後端拿到 sku，
  // 這個 map 是為了避免多次 query 後端，所以用 artworkId 存著 sku
  const artworkIdToSkuMap = new Map<string, string>()
  async function getPrice(sku: string, artworkId: string): Promise<null | PricePlan> {
    await init()
    artworkIdToSkuMap.set(artworkId, sku)
    return pricePlanMap.get(sku) ?? null
  }

  async function getPriceByArtworkId(artworkId: string): Promise<null | PricePlan> {
    const map = await getPriceByArtworkIds([artworkId])
    return map.get(artworkId) ?? null
  }
  async function getPriceByArtworkIds(
    artworkIds: string[],
  ): Promise<ReadonlyMap<string, PricePlan>> {
    await init()
    const missingSkuArtworkIds: string[] = []
    const result = new Map<string, PricePlan>()
    for (const artworkId of artworkIds) {
      const sku = artworkIdToSkuMap.get(artworkId)
      if (sku === undefined) {
        missingSkuArtworkIds.push(artworkId)
        continue
      }
      const pricePlan = pricePlanMap.get(sku)
      if (pricePlan === undefined) {
        throw new Error(`price info not found for sku: ${sku}`)
      }
      result.set(artworkId, pricePlan)
    }
    if (missingSkuArtworkIds.length !== 0) {
      const { data } = await useQuery({
        query: PriceSkuDocument,
        variables: {
          ids: missingSkuArtworkIds,
          location: backendLocation,
        },
      })
      if (data.value === null) {
        throw new Error('price info query failed')
      }
      const idSkus = data.value.artworksForDitto.edges.map((x) => x.node)
      for (const id of missingSkuArtworkIds) {
        const idSku = idSkus.find((x) => x.id === id)
        const sku = idSku?.background?.pricePlan?.sku ?? idSku?.stickerGroup?.pricePlan?.sku
        if (sku === undefined) {
          continue
        }
        const priceInfo = pricePlanMap.get(sku)
        if (priceInfo === undefined) {
          throw new Error(`price info not found for sku: ${sku}`)
        }
        result.set(id, priceInfo)
      }
    }
    return result
  }

  async function calculateCollagePrice(
    collage: CollageCommonFieldsFragment,
  ): Promise<null | PriceInfo> {
    await init()
    let price = 0
    let compareAtPrice = 0
    const backgroundPricePlan = collage.background?.pricePlan ?? null
    const backgroundId = collage.background?.id ?? null
    if (collage.backgroundVisible && backgroundPricePlan !== null && backgroundId !== null) {
      const backgroundPriceInfo = await getPrice(backgroundPricePlan.sku, backgroundId)
      if (backgroundPriceInfo === null) {
        return null
      }
      price += backgroundPriceInfo.price
      compareAtPrice += backgroundPriceInfo.compareAtPrice
    }

    // 為了讓價格去重複用的
    const groupIds = new Set()
    for (const stickerItem of collage.stickerOnCollages) {
      const stickerGroup = stickerItem.sticker.group
      const stickerGroupPricePlan = stickerGroup.pricePlan ?? null
      if (stickerItem.visible && !groupIds.has(stickerGroup.id) && stickerGroupPricePlan !== null) {
        groupIds.add(stickerGroup.id)
        const stickerPriceInfo = await getPrice(stickerGroupPricePlan.sku, stickerGroup.id)
        if (stickerPriceInfo === null) {
          return null
        }
        price += stickerPriceInfo.price
        compareAtPrice += stickerPriceInfo.compareAtPrice
      }
    }
    return {
      price,
      compareAtPrice,
    }
  }
  void init()

  return { init, getPrice, calculateCollagePrice, getPriceByArtworkIds, getPriceByArtworkId }
})
